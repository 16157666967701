




















































































































































































































































































































































































































































































































































































































































































































































































































































































































import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import {
  BButton,
  BSpinner,
  BCol,
  BRow,
  BBadge,
  VBTooltip,
  BPopover,
  BTooltip,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BFormCheckbox,
  BAlert,
  BFormSelectOption,
  BFormSelectOptionGroup,
  BAvatar,
  BAvatarGroup,
} from "bootstrap-vue";
import { required } from "@validations";
import { Component, Vue, Watch } from "vue-property-decorator";
import {
  FicheIncident,
  FicheIncidentStatut,
  UpdateFicheIncidentModel,
} from "@/api/models/fichesIncident/ficheIncident";
import { FicheIncidentGraviteEnum } from "@/api/models/enums/ficheIncidentGraviteEnum";
import { Processus } from "@/api/models/fichesIncident/processus";
import { PaginatedList } from "@/api/models/common/paginatedList";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { UtilisateurTrombinoscope } from "@/api/models/utilisateurs/utilisateur";
import RessifnetDateInput from "@/components/inputs/RessifnetDateInput.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";

Vue.directive("b-tooltip", VBTooltip);
@Component({
  components: {
    BButton,
    BSpinner,
    BAvatar,
    BAvatarGroup,
    BFormGroup,
    BAlert,
    BFormInput,
    BFormCheckbox,
    BFormSelectOption,
    SearchableVueSelect,
    BFormSelectOptionGroup,
    BFormSelect,
    BFormTextarea,
    ValidationObserver,
    ValidationProvider,
    BPopover,
    BTooltip,
    BBadge,
    BCol,
    BRow,
    RessifnetDateInput,
  },
})
export default class DetailsFicheIncident extends Vue {
  ficheIncident: FicheIncident | null = null;
  mainLoading = false;
  updating = false;

  statutOptions: FicheIncidentStatut[] = [];
  localisationOptions: string[] = [];
  typesIncidentOptions: string[] = [];
  processusOptions: Processus[] = [];
  graviteEnum = FicheIncidentGraviteEnum;

  avatarColors: string[] = [
    "#3498db", // Bleu clair
    "#2ecc71", // Vert menthe
    "#e74c3c", // Rouge doux
    "#f39c12", // Orange
    "#9b59b6", // Violet doux
    "#34495e", // Bleu nuit
    "#1abc9c", // Vert d'eau
    "#e67e22", // Orange foncé
    "#95a5a6", // Gris clair
    "#d35400", // Orange brûlé
  ];
  avatarColorsMap: { [key: string]: string } = {};
  required = required;
  listUtilisateurs: UtilisateurTrombinoscope[] = [];

  selectedResponsable: UtilisateurTrombinoscope | null = null;
  selectedAssignes: UtilisateurTrombinoscope[] = [];
  selectedProcessus: Processus | null = null;

  async created() {
    this.mainLoading = true;
    await this.loadStatutOptions();
    await this.loadTypesIncidentOptions();
    await this.loadListProcesssus();
    await this.loadLocalisationsOptions();

    // Récupérer la fiche incident
    await this.getFicheIncident(this.$route.params.id);
  }

  getInitials(nom: string | undefined, prenom: string | undefined) {
    const initialNom = nom ? nom.charAt(0).toUpperCase() : "";
    const initialPrenom = prenom ? prenom.charAt(0).toUpperCase() : "";
    return `${initialNom}${initialPrenom}`;
  }
  getRandomAvatarColor(key: string) {
    // Si la couleur existe déjà pour cet avatar, on la réutilise
    if (!this.avatarColorsMap[key]) {
      const randomIndex = Math.floor(Math.random() * this.avatarColors.length);
      this.avatarColorsMap[key] = this.avatarColors[randomIndex];
    }
    return this.avatarColorsMap[key];
  }

  get hasRights(): boolean {
    return (
      this.$can("FICHES_INCIDENT:update") ||
      this.$can("FICHES_INCIDENT:admin") ||
      this.ficheIncident?.userIsResponsable ||
      this.ficheIncident?.userIsPilote
    );
  }

  async getFicheIncident(ficheIncidentId: string | undefined) {
    await this.$http.myressif.fichesIncident.getById(ficheIncidentId).then(
      async (response: FicheIncident) => {
        this.ficheIncident = response;
        this.selectedResponsable = response.responsable!;
        this.selectedAssignes = response.assignes!;
        this.selectedProcessus = response.processus!;
        if (response.userIsResponsable && !this.ficheIncident.vu) {
          this.ficheIncident.vu = true;
          await this.updateFicheIncident(false);
        }

        this.mainLoading = false;
      },
      (error: any) => {
        errorAlert.fire({
          title: "Problème de chargement de la fiche incident",
          text: error.message,
        });
      }
    );
  }

  get selectedStatut() {
    return this.getFicheIncidentStatut(this.ficheIncident!.statut!);
  }

  @Watch("ficheIncident.statut")
  async onStatutChange() {
    await this.updateStatutStyle();
  }

  async updateStatutStyle() {
    const selectElement: any = this.$el.querySelector(
      ".custom-select-dropdown select"
    );
    if (selectElement) {
      selectElement.style.backgroundColor = this.selectedStatut.couleur;
      selectElement.style.borderColor = this.selectedStatut.couleur;
      selectElement.style.color = "#fff";
    }
  }

  getGraviteColor(gravite: number) {
    switch (gravite) {
      case this.graviteEnum.FAIBLE:
        return "#ffd100";
      case this.graviteEnum.MOYENNE:
        return "#ff9f43";
      case this.graviteEnum.HAUTE:
        return "#ea5455";
      default:
        return "#343a40";
    }
  }

  getGraviteLabel(gravite: number) {
    return this.graviteEnum[gravite];
  }

  getFicheIncidentStatut(id: number): FicheIncidentStatut {
    var statut = this.statutOptions.find((option: any) => option.id === id);
    if (statut && statut.valeur) return statut;
    else return { id: 0, valeur: "Inconnu", couleur: "#343a40", resolu: false };
  }

  async loadStatutOptions() {
    await this.$http.myressif.parametres.getByNom("fi_statut").then(
      (res: any) => {
        if (res.valeur) {
          let options = JSON.parse(res.valeur);
          this.statutOptions = options.map((option: FicheIncidentStatut) => {
            return {
              id: option.id,
              valeur: option.valeur,
              couleur: option.couleur,
              resolu: option.resolu,
              default: option.defaut,
            };
          });
        }
      },
      (error: any) => {
        errorAlert.fire({
          title: "Problème de chargement des statuts",
          text: error.message,
        });
      }
    );
  }

  async loadLocalisationsOptions() {
    await this.$http.myressif.parametres.getByNom("fi_localisation").then(
      (res: any) => {
        if (res.valeur) {
          let options: [] = JSON.parse(res.valeur);
          this.localisationOptions.push(...options);
        }
      },
      (error: any) => {
        errorAlert.fire({
          title: "Problème de chargement des localisations",
          text: error.message,
        });
      }
    );
  }

  async loadListProcesssus() {
    await this.$http.myressif.processus.searchPaginatedList(1, 20).then(
      (response: PaginatedList<Processus>) => {
        this.processusOptions = response.items;
      },
      (error: any) => {
        errorAlert.fire({
          title: "Problème de chargement des processus",
          text: error.message,
        });
      }
    );
  }

  async loadTypesIncidentOptions() {
    await this.$http.myressif.parametres.getByNom("fi_type_incident").then(
      (res: any) => {
        if (res.valeur) {
          let options: [] = JSON.parse(res.valeur);
          this.typesIncidentOptions.push(...options);
        }
      },
      (error: any) => {
        errorAlert.fire({
          title: "Problème de chargement des types d'incident",
          text: error.message,
        });
      }
    );
  }

  async openModalResponsable() {
    await this.searchUtilisateurs();
    this.$bvModal.show("modalResponsable");
  }

  async openModalAssignes() {
    await this.searchUtilisateurs();
    this.$bvModal.show("modalAssignes");
  }

  async updateResponsable() {
    this.ficheIncident!.responsable = this.selectedResponsable!;
  }

  async updateAssignes() {
    this.ficheIncident!.assignes = this.selectedAssignes;
  }

  async searchUtilisateurs(search?: string, equipeId?: string) {
    var assignes = await this.$http.myressif.trombinoscope
      .paginatedList(1, 10, search ?? "", "nom", "nom", false, equipeId)
      .then(
        (response: PaginatedList<UtilisateurTrombinoscope>) => {
          this.listUtilisateurs = response.items;
          return response.items;
        },
        (error: any) => {
          errorAlert.fire({
            title: "Problème de chargement des assignés",
            text: error.message,
          });
          return [];
        }
      );

    return assignes;
  }

  @Watch("ficheIncident.nonConformite")
  async onNonConformiteChange() {
    if (!this.ficheIncident!.nonConformite && this.ficheIncident!.planAction) {
      this.ficheIncident!.planAction = null;
    } 
    else if (
      this.ficheIncident!.nonConformite &&
      !this.ficheIncident!.planAction
    ) {
      this.ficheIncident!.planAction = {
        dateMesureEfficacite: "",
        efficace: false,
        indicateursEfficacite: "",
        axesAmelioration: ""
      };

      this.$nextTick(() => {
        const planAction = this.$el.querySelector(
          "#date-demarrage-plan-action"
        );
        if (planAction) {
          planAction.scrollIntoView({ behavior: "smooth" });
        }
      });
    }
  }

  async askDeleteFicheIncident() {
    this.$bvModal
      .msgBoxConfirm(
        "Etes-vous sûr de vouloir supprimer cette fiche incident ?",
        {
          title: "Confirmation de suppression",
          size: "sm",
          okVariant: "primary",
          okTitle: "Oui",
          cancelTitle: "Non",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        }
      )
      .then(async (value) => {
        if (value === true) {
          await this.confirmDelete();
        } else {
          return;
        }
      });
  }

  async confirmDelete() {
    await this.$http.myressif.fichesIncident.delete(this.$route.params.id).then(
      (response: any) => {
        successAlert.fire({
          title: "Suppression de la fiche incident",
          text: "Suppression effectuée avec succès",
        });
        this.goToList();
      },
      (error: any) => {
        errorAlert.fire({
          title: "Oops",
          text: error.message,
        });
      }
    );
  }

  async onProcessusChange() {
    this.ficheIncident!.processus = this.selectedProcessus!;
    this.$bvToast.toast(`Les assignés et le responsable changeront à la sauvegarde`, {
          title: 'Changement de processus',
          autoHideDelay: 5000
        })
  }

  async updateFicheIncident(fireAlert: boolean = true) {
    this.updating = true;

    var updateModel: UpdateFicheIncidentModel = {
      responsableId: this.ficheIncident!.responsable!.userId,
      assignesId: this.ficheIncident!.assignes!.map(
        (assigne) => assigne.userId!
      ),
      description: this.ficheIncident!.description,
      eventuellesSuggestions: this.ficheIncident!.eventuellesSuggestions,
      gravite: this.ficheIncident!.gravite,
      localisation: this.ficheIncident!.localisation,
      nonConformite: this.ficheIncident!.nonConformite,
      vu: this.ficheIncident!.vu,
      processusId: this.ficheIncident!.processus!.id,
      statut: this.ficheIncident!.statut,
      titre: this.ficheIncident!.titre,
      traitementImmediat: this.ficheIncident!.traitementImmediat,
      typeIncident: this.ficheIncident!.typeIncident,
      dateIncident: this.ficheIncident!.dateIncident,
      commentaireInterne: this.ficheIncident!.commentaireInterne,
      commentaireExterne: this.ficheIncident!.commentaireExterne,
      planAction: this.ficheIncident!.planAction ?? undefined,
    };

    await this.$http.myressif.fichesIncident
      .update(this.$route.params.id, updateModel)
      .then(
        async (response: any) => {
          if (fireAlert) {
            successAlert.fire({
              title: "Modification de la fiche incident",
              text: "Modification effectuée avec succès",
            });

            await this.getFicheIncident(this.$route.params.id);
          }
        },
        (error: any) => {
          errorAlert.fire({
            title: "Oops",
            text: error.message,
          });
        }
      );

    this.updating = false;
  }

  goToList() {
    this.$router.push({ name: "fiches-incident" });
  }
}
